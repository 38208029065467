
// @ts-ignore
import { Component, Inject, Vue } from 'vue-property-decorator';
import { IInsurance } from '@/shared/model/insurance.model';
import { IAnswer } from '@/shared/model/answer.model';
import InsuranceService from '@/entities/insurance/insurance.service';
import AlertService from '@/shared/alert/alert.service';
import { calculateWeightedValue } from '@/pages/report/lib/answerCalculationService';
import InsuranceCard from '@/pages/report/new/questions/InsuranceCard.vue';
import { ICategory } from '@/shared/model/category.model';

@Component({
  components: {
    InsuranceCard,
  },
})
export default class Questions extends Vue {
  @Inject('insuranceService') private insuranceService: () => InsuranceService;
  @Inject('alertService') private alertService: () => AlertService;
  private insurances: IInsurance[] = [];

  get customers() {
    return this.$store.getters.customer;
  }

  get savedAnswers(): IAnswer[] {
    return this.$store.getters.answers;
  }

  /**
   * get all insurance categories that exist in the store
   */
  get categories(): ICategory[] {
    const categories = this.insurances.map(item => item.category);
    // remove duplicates
    return categories.filter(
      (item, index, self) => self.findIndex(t => t.id === item.id) === index
    );
  }

  /**
   * check if all insurances are completed
   * @return if all insurances are completed
   */
  get isFullyCompleted(): boolean {
    for (const ins of this.insurances) {
      const isCompleted = this.isComplete(ins);
      if (!isCompleted) {
        return false;
      }
    }
    return true;
  }

  async mounted() {
    await this.init();
  }

  /**
   * goes to next page (preview)
   */
  async submitAnswers() {
    //calculate weighted value for all answers
    this.savedAnswers.forEach(answer => {
      //skip ignored answers
      if (answer.ignored) {
        return;
      }
      const category = this.categories.find(
        item => item.description === answer.categoryDescription
      );

      answer.weightedValue = calculateWeightedValue(
        this.savedAnswers,
        answer.customer,
        category,
        answer.selectedPercentage
      );
    });

    await this.$router.push({ name: 'ReportPreview' });
  }

  /**
   * redirects to customer selection view
   */
  async backToCustomerSelection(): Promise<void> {
    await this.$router.push({ name: 'ReportCustomerSelection', query: { back: 'true' } });
  }

  /**
   * check if given insurance is complete - all customers have answered or ignored
   * @param insurance
   * @returns true if insurance is complete else false
   */
  public isComplete(insurance: IInsurance): boolean {
    const answers = this.$store.getters.answers;
    if (answers == null || this.customers == null) {
      return true;
    }
    const answersForInsurance = answers.filter(item => {
      return (
        item.insuranceDescription === insurance.description &&
        item.categoryDescription === insurance.category.description
      );
    });
    return answersForInsurance.length === this.customers.length;
  }

  /**
   * loads all insurances from backend
   * @private
   */
  private async loadInsuranceQuestionsFull(): Promise<void> {
    try {
      const res = await this.insuranceService().retrieve({
        page: 0,
        size: 1000,
        sort: ['category.orderNumber,asc', 'description,asc'],
      });
      this.insurances = res.data;
    } catch (e) {
      this.alertService().showError(this, 'Fehler beim Laden der Sparten');
    }
  }

  /**
   * generates string array of warnings for empty answers
   * @return string array of warnings
   */
  getEmptyAnswerMessage(): string[] {
    const answers = this.$store.getters.answers;
    if (answers == null || this.insurances == null || this.customers == null) {
      return [];
    }

    const warnings: string[] = [];
    for (const insurance of this.insurances) {
      for (const customer of this.customers) {
        const ans = answers.filter(item => {
          return (
            item.customer.id === customer.id &&
            item.insuranceDescription === insurance.description &&
            item.categoryDescription === insurance.category.description
          );
        });
        if (ans.length === 0) {
          warnings.push(`${insurance.description} - ${customer.firstName} ${customer.lastName}`);
        }
      }
    }
    return warnings;
  }

  private async init() {
    await this.loadInsuranceQuestionsFull();
  }
}

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "card card-body mt-3 shadow-sm border-0",
      class: _setup.getCardStatusClasses(),
    },
    [
      _c(
        "div",
        {
          staticClass:
            "question-header d-flex justify-content-between align-items-baseline",
        },
        [
          _c("div", [
            _c("p", { staticClass: "font-weight-bold mb-0" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_setup.props.insurance.description) +
                  "\n      "
              ),
            ]),
            _vm._v(" "),
            _c("small", { staticClass: "text-muted" }, [
              _vm._v(
                "(" + _vm._s(_setup.props.insurance.category.description) + ")"
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", [
            !_setup.props.insurance.isRequired
              ? _c(
                  "button",
                  {
                    staticClass:
                      "btn btn-sm text-right w-100 b-0 p-0 ignore-all-button shadow-none",
                    attrs: {
                      title: "Alle Antworten ignorieren",
                      type: "button",
                    },
                    on: {
                      click: function ($event) {
                        return _setup.ignoreAllAnswers()
                      },
                    },
                  },
                  [
                    _setup.areAllAnswerIgnored()
                      ? [
                          _c("font-awesome-icon", {
                            attrs: { icon: "eye-slash" },
                          }),
                        ]
                      : [_c("font-awesome-icon", { attrs: { icon: "eye" } })],
                  ],
                  2
                )
              : _vm._e(),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row" },
        [
          _vm._l(_setup.props.customers, function (customer) {
            return _c(
              "div",
              {
                key: "ignore" + _setup.props.insurance.id + "_" + customer.id,
                staticClass: "col text-center",
              },
              [
                !_setup.props.insurance.isRequired
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-sm w-100 ignore-button shadow-none",
                        attrs: {
                          title: _setup.isAnswerIgnored(customer)
                            ? "Antwort nicht ignorieren"
                            : "Antwort ignorieren",
                          type: "button",
                        },
                        on: {
                          click: function ($event) {
                            return _setup.ignoreAnswer(customer)
                          },
                        },
                      },
                      [
                        _setup.isAnswerIgnored(customer)
                          ? [
                              _c("font-awesome-icon", {
                                attrs: { icon: "eye-slash" },
                              }),
                            ]
                          : [
                              _c("font-awesome-icon", {
                                attrs: { icon: "eye" },
                              }),
                            ],
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    style: _setup.isAnswerIgnored(customer)
                      ? "color: #bababa "
                      : "",
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(customer.lastName) +
                        " " +
                        _vm._s(customer.firstName) +
                        "\n      "
                    ),
                  ]
                ),
              ]
            )
          }),
          _vm._v(" "),
          _c("div", { staticClass: "col-6 text-right" }),
        ],
        2
      ),
      _vm._v(" "),
      _vm._l(_setup.getOptionsToInsurance(), function (opt) {
        return [
          opt.description
            ? _c(
                "div",
                {
                  key: _vm.insurance.id + "" + opt.id,
                  staticClass: "py-2 question-row",
                },
                [
                  _c(
                    "div",
                    { staticClass: "row" },
                    [
                      _vm._l(_vm.customers, function (customer) {
                        return _c(
                          "div",
                          {
                            key:
                              _vm.insurance.id + "" + opt.id + "" + customer.id,
                            staticClass: "col text-center",
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn w-100 h-100 shadow-none",
                                attrs: {
                                  disabled: _setup.isAnswerIgnored(customer),
                                  type: "button",
                                },
                                on: {
                                  click: function ($event) {
                                    return _setup.toggleAnswer(customer, opt)
                                  },
                                },
                              },
                              [
                                _setup.isAnswerLoggedIn(customer, opt)
                                  ? [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: "square-check" },
                                      }),
                                    ]
                                  : [
                                      _c("font-awesome-icon", {
                                        staticClass: "text-black",
                                        attrs: { icon: "fa-regular fa-square" },
                                      }),
                                    ],
                              ],
                              2
                            ),
                          ]
                        )
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-6 d-flex justify-content-between align-items-center",
                        },
                        [
                          _c("p", [_vm._v(_vm._s(opt.description))]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v("(" + _vm._s(opt.percentage.value) + "%)"),
                          ]),
                        ]
                      ),
                    ],
                    2
                  ),
                ]
              )
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
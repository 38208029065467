var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "boxed-layout" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", [
      _c(
        "button",
        {
          staticClass: "btn",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              return _vm.backToCustomerSelection()
            },
          },
        },
        [
          _c("font-awesome-icon", { attrs: { icon: "chevron-left" } }),
          _vm._v("\n      Zurück\n      "),
          _c("small", { staticClass: "text-muted" }, [
            _vm._v(" zur Personenauswahl "),
          ]),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("hr"),
    _vm._v(" "),
    _vm.insurances &&
    _vm.insurances.length > 0 &&
    _vm.categories &&
    _vm.customers
      ? _c(
          "div",
          _vm._l(_vm.insurances, function (ins) {
            return _c("InsuranceCard", {
              key: ins.id + "-card",
              staticClass: "card card-body mt-3 shadow-sm border-0",
              attrs: {
                categories: _vm.categories,
                customers: _vm.customers,
                insurance: ins,
              },
            })
          }),
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c("hr"),
    _vm._v(" "),
    _c("div", { staticClass: "mt-3 d-flex justify-content-between" }, [
      _c("div", [
        _c(
          "button",
          {
            staticClass: "btn",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.backToCustomerSelection()
              },
            },
          },
          [
            _c("font-awesome-icon", { attrs: { icon: "chevron-left" } }),
            _vm._v("\n        Zurück\n        "),
            _c("small", { staticClass: "text-muted" }, [
              _vm._v(" zur Personenauswahl "),
            ]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "text-right d-flex flex-column align-items-end" },
        [
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: { disabled: !_vm.isFullyCompleted, type: "button" },
              on: {
                click: function ($event) {
                  return _vm.submitAnswers()
                },
              },
            },
            [
              _vm._v("\n        Weiter\n        "),
              _c("font-awesome-icon", { attrs: { icon: "chevron-right" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("small", { staticClass: "text-muted" }, [
            _vm.isFullyCompleted
              ? _c("span", [
                  _vm._v("\n          Weiter zur Zusammenfassung "),
                  _c("br"),
                  _vm._v("\n          Alle Fragen beantwortet\n        "),
                ])
              : _c("span", [
                  _vm._v(" Bitte beantworten Sie alle Fragen um fortzufahren "),
                ]),
          ]),
        ]
      ),
    ]),
    _vm._v(" "),
    !_vm.isFullyCompleted
      ? _c(
          "div",
          { staticClass: "text-right mt-3" },
          [
            _c("small", { staticClass: "text-muted font-weight-bold" }, [
              _vm._v(
                "\n      Es fehlen noch Eingaben zu folgenden Punkten:\n    "
              ),
            ]),
            _vm._v(" "),
            _vm._l(_vm.getEmptyAnswerMessage(), function (warning, index) {
              return _c("div", { key: index }, [
                _c(
                  "small",
                  {
                    staticClass: "text-muted",
                    domProps: { innerHTML: _vm._s(warning) },
                  },
                  [_vm._v(_vm._s(warning))]
                ),
              ])
            }),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "d-flex justify-content-between" }, [
      _c("h2", { staticClass: "mt-3" }, [_vm._v("Fragen")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }